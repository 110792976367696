import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import axios from 'axios';
import { v4 } from 'uuid';

import { SurveyContext } from '../Context';

import Title from '../components/Title';
import Question from '../components/Question';
import Success from '../components/Success';

export default function View() {
  const [surveyDetails, setSurveyDetails] = useState(null);
  const [answers, setAnswers] = useState([]);

  const [message, setMessage] = useState('');

  const history = useHistory();

  const { API_URL, loggedInUser } = useContext(SurveyContext);
  const { surveyID } = useParams();

  useEffect(() => {
    axios
      .post(
        `${API_URL}/survey/main/fetch/one`,
        {
          surveyID: surveyID,
          uid: loggedInUser.uid ? loggedInUser.uid : '',
        },
        {
          headers: {
            Authorization: `Bearer ${loggedInUser.jwt ? loggedInUser.jwt : ''}`,
          },
        }
      )
      .then((res) => {
        if (res.data.error === 0) {
          if (
            res.data.survey.draft &&
            (!loggedInUser ||
              !loggedInUser.uid ||
              loggedInUser.uid !== res.data.survey.uid)
          ) {
            history.push('/');
          }

          setSurveyDetails(res.data.survey);
          setAnswers(
            res.data.survey.questions.map(({ questionID, type }) => ({
              questionID,
              type,
              data: '',
            }))
          );
        } else {
          console.log(res.data);
        }
      });
    // eslint-disable-next-line
  }, []);

  const submitAnswer = () => {
    if (
      surveyDetails.questions.every((detail, index) => {
        if (detail.required && answers[index].data.length <= 0) {
          return false;
        } else {
          return true;
        }
      })
    ) {
      let groupAnswerID = v4();

      axios
        .post(
          `${API_URL}/survey/answer/submit/multiple`,
          {
            uid: loggedInUser.uid ? loggedInUser.uid : '',
            surveyID: surveyID,
            groupAnswerID,
            answers: [...answers],
          },
          {
            headers: {
              Authorization: `Bearer ${
                loggedInUser.jwt ? loggedInUser.jwt : ''
              }`,
            },
          }
        )
        .then((res) => {
          if (res.data.error !== 0) {
            console.log(res.data);
          }
        });

      setMessage('Thank you for answering the survey!');
      setTimeout(() => {
        setSurveyDetails({});
        setAnswers([]);
        history.push('/');
      }, 2000);
    } else {
      alert('Check and answer required fields please.');
    }
  };

  const surveyCard =
    !surveyDetails || JSON.stringify(surveyDetails) === '{}'
      ? null
      : Title(
          surveyDetails.name,
          surveyDetails.description,
          surveyDetails.surveyID,
          surveyDetails.private,
          surveyDetails.draft
        );

  const questions =
    !surveyDetails ||
    JSON.stringify(surveyDetails) === '{}' ||
    answers.length <= 0
      ? null
      : surveyDetails.questions.map((question) => {
          return Question({ ...question, answers, setAnswers });
        });

  const submitButton =
    surveyDetails &&
    JSON.stringify(surveyDetails) !== '{}' &&
    surveyDetails.questions.length > 0 ? (
      <div
        className="ease-in-out duration-300 sm:w-1/3 w-11/12 bg-custom-400 rounded-lg py-2 font-nunito font-bold sm:text-2xl text-lg my-2 text-gray-900 text-center hover:opacity-75 focus:opacity-75"
        onClick={() => submitAnswer()}
      >
        Submit
      </div>
    ) : null;

  return (
    <div className="w-full flex flex-col items-center">
      {surveyCard}
      <div className="w-full flex flex-col items-center">
        {message ? (
          Success(message, 'green')
        ) : (
          <>
            {questions} {submitButton}
          </>
        )}
      </div>
    </div>
  );
}
