import React, { useState, useContext, useEffect } from 'react';

import { SurveyContext } from '../Context';
import { useHistory, useParams } from 'react-router-dom';

import axios from 'axios';

import Title from '../components/Title';
import Button from '../components/Button';

export default function EditSurvey() {
  const { API_URL, surveys, loggedInUser } = useContext(SurveyContext);

  const { surveyID } = useParams();

  const [currentSurvey, setCurrentSurvey] = useState({});
  const [questions, setQuestions] = useState([]);
  const [answers, setAnswers] = useState([]);

  const history = useHistory();

  useEffect(() => {
    if (loggedInUser.uid === undefined) {
      history.push('/');
    }

    setCurrentSurvey(surveys.find((survey) => survey.surveyID === surveyID));

    if (!currentSurvey) history.push('/my_surveys');

    axios
      .post(
        `${API_URL}/survey/main/fetch/one`,
        {
          uid: loggedInUser.uid,
          surveyID: surveyID,
        },
        { headers: { Authorization: `Bearer ${loggedInUser.jwt}` } }
      )
      .then((res) => {
        if (res.data.error === 0) {
          setQuestions(res.data.survey.questions);
          setAnswers(res.data.survey.answers);
        } else {
          console.log(res.data);
        }
      });
    // eslint-disable-next-line
  }, []);

  const surveyCard = Title(
    currentSurvey.name,
    currentSurvey.description,
    currentSurvey.surveyID,
    currentSurvey.private,
    currentSurvey.draft
  );

  const surveyQuestions = questions.map((question) => {
    const surveyAnswers = answers
      .filter((a) => a.questionID === question.questionID)
      .map((answer) => (
        <li className="text-gray-800" key={answer.answerID}>
          [{answer.groupAnswerID}]: {answer.data.join(', ')}
        </li>
      ));

    return (
      <div
        className="w-11/12 bg-custom-400 my-2 sm:px-4 sm:py-8 px-2 py-4 rounded-lg flex flex-col shadow-lg"
        key={`q-${question.questionID}`}
      >
        <div className="font-roboto sm:text-4xl text-xl text-gray-800 font-bold tracking-wide flex">
          {question.text}
        </div>
        <div className="flex flex-col list-disc">{surveyAnswers}</div>
      </div>
    );
  });

  return (
    <div className="w-full flex flex-col items-center">
      {surveyCard}
      {surveyQuestions}
      <div className="flex sm:flex-row flex-col justify-around sm:items-start items-center mt-2 mb-4 w-11/12">
        <Button
          action={() => history.push('/my_surveys')}
          color="orange"
          transition="color"
          width="full"
        >
          Back to my surveys
        </Button>
      </div>
    </div>
  );
}
