import React from 'react';
import Button from './Button';

export default function EditQuestion({
  questionID,
  text,
  type,
  required,
  data,
  setQuestions,
}) {
  const setNewTitle = (value) => {
    setQuestions((prev) => {
      let newQuestions = [];

      prev.forEach((q) => {
        if (q.questionID === questionID) {
          newQuestions.push({
            questionID,
            text: value,
            type,
            required,
            data,
          });
        } else {
          newQuestions.push({ ...q });
        }
      });

      return newQuestions;
    });
  };

  const setHasType = (value) => {
    setQuestions((prev) => {
      let newQuestions = [];

      prev.forEach((q) => {
        if (q.questionID === questionID) {
          newQuestions.push({
            questionID,
            text,
            type: value,
            required,
            data,
          });
        } else {
          newQuestions.push({ ...q });
        }
      });

      return newQuestions;
    });
  };

  const setIsRequired = (value) => {
    setQuestions((prev) => {
      let newQuestions = [];

      prev.forEach((q) => {
        if (q.questionID === questionID) {
          newQuestions.push({
            questionID,
            text,
            type,
            required: value,
            data,
          });
        } else {
          newQuestions.push({ ...q });
        }
      });

      return newQuestions;
    });
  };

  const setData = (value) => {
    setQuestions((prev) => {
      let newQuestions = [];

      prev.forEach((q) => {
        if (q.questionID === questionID) {
          newQuestions.push({
            questionID,
            text,
            type,
            required,
            data: value.replace(/\s/g, '').split(','),
          });
        } else {
          newQuestions.push({ ...q });
        }
      });

      return newQuestions;
    });
  };

  const deleteQuestion = () => {
    setQuestions((prev) => prev.filter((q) => q.questionID !== questionID));
  };

  let answerContainer = (
    <textarea
      name="answer"
      id="answer"
      className="w-full bg-gray-300 text-gray-800 placeholder-gray-600 rounded mt-2 p-2 font-roboto tracking-wide sm:text-md text-sm"
      placeholder="Enter your answer..."
      required={required}
    />
  );

  if (type === 'choice') {
    answerContainer = data.map((d, i) => (
      <label
        className="mt-2 -mb-4 p-2 font-roboto tracking-wider font-bold sm:w-1/6 text-gray-800"
        key={`${questionID}-choice-${i}`}
      >
        <input type="radio" name="answer" value={d} required={required} /> {d}
      </label>
    ));
  } else if (type === 'multiple') {
    answerContainer = data.map((d, i) => (
      <label
        className="mt-2 -mb-4 p-2 font-roboto tracking-wider font-bold sm:w-1/6 text-gray-800"
        key={`${questionID}-mult-${i}`}
      >
        <input type="checkbox" name="answer" required={required} /> {d}
      </label>
    ));
  }

  return (
    <div
      className="w-11/12 bg-custom-400 my-2 sm:px-4 sm:py-8 px-2 py-4 rounded-lg flex flex-col shadow-lg mx-auto"
      key={`q-edit-${questionID}`}
    >
      <div className="font-roboto sm:text-4xl text-xl text-gray-800 font-bold tracking-wide flex">
        <input
          type="text"
          value={text}
          onChange={(e) => setNewTitle(e.target.value)}
          className="p-2 font-roboto sm:text-4xl text-xl text-gray-800 font-bold tracking-wide sm:w-3/5 w-full rounded-lg mb-2"
          placeholder="Enter title for question..."
        />
        <p className="ml-1 text-yellow-200">{required === 'true' ? '*' : ''}</p>
      </div>
      <div className="flex flex-col p-2 rounded-lg border-2 border-custom-500">
        <div className="font-roboto sm:text-2xl text-lg text-gray-800 font-bold tracking-wide flex -mb-2 mt-2">
          Is it required to answer this question?
        </div>
        <div className="flex flex-col">
          <div className="flex">
            <label className="p-2 font-nunito tracking-wider font-bold sm:w-1/6 text-gray-800">
              <input
                type="radio"
                name="required"
                value="choice"
                onChange={(e) => setIsRequired(true)}
              />{' '}
              Yes
            </label>
          </div>
          <div className="flex">
            <label className="p-2 -mt-4 font-nunito tracking-wider font-bold sm:w-1/6 text-gray-800">
              <input
                type="radio"
                name="required"
                value="multiple"
                onChange={(e) => setIsRequired(false)}
              />{' '}
              No
            </label>
          </div>
        </div>
      </div>
      <div className="flex flex-col p-2 rounded-lg border-2 border-custom-500 mt-2">
        <div className="font-roboto sm:text-2xl text-lg text-gray-800 font-bold tracking-wide flex -mb-2">
          Type of Question
        </div>
        <div className="flex flex-col">
          <div className="flex">
            <label className="p-2 font-nunito tracking-wider font-bold sm:w-1/6 text-gray-800">
              <input
                type="radio"
                name="type"
                id="type"
                value="choice"
                onChange={(e) => setHasType(e.target.value)}
              />{' '}
              Choice
            </label>
          </div>
          <div className="flex">
            <label className="p-2 -mt-4 font-nunito tracking-wider font-bold sm:w-1/6 text-gray-800">
              <input
                type="radio"
                name="type"
                id="type"
                value="multiple"
                onChange={(e) => setHasType(e.target.value)}
              />{' '}
              Multiple
            </label>
          </div>
          <div className="flex">
            <label className="p-2 -mt-4 font-nunito tracking-wider font-bold sm:w-1/6 text-gray-800">
              <input
                type="radio"
                name="type"
                id="type"
                value="text"
                onChange={(e) => setHasType(e.target.value)}
              />{' '}
              Text
            </label>
          </div>
        </div>
      </div>
      {type.toLowerCase().trim() !== 'text' && (
        <div className="flex flex-col p-2 rounded-lg border-2 border-custom-500 mt-2">
          <div className="font-roboto sm:text-2xl text-lg text-gray-800 font-bold tracking-wide flex mt-2">
            Comma seperated list of possible answers
          </div>
          <div className="flex flex-col">
            <input
              type="text"
              name="data"
              value={data.join(',')}
              className="p-2 mt-2 font-nunito tracking-wider text-gray-800 rounded-lg"
              onChange={(e) => setData(e.target.value)}
            />
          </div>
        </div>
      )}
      <div className="flex flex-col">{answerContainer}</div>
      <div className="flex flex-col items-center justify-center mt-4 -mb-2 w-full">
        <Button
          action={() => deleteQuestion()}
          color="orange"
          transition="color"
          width="full"
        >
          Delete Question
        </Button>
      </div>
    </div>
  );
}
