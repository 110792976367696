import React, { useState } from 'react';
import { Switch, Route } from 'react-router-dom';

import Header from './components/Header';
import Home from './screens/Home';
import View from './screens/View';

import Surveys from './screens/Surveys';
import NewSurvey from './screens/NewSurvey';
import EditSurvey from './screens/EditSurvey';

import Questions from './screens/Questions';
import Answers from './screens/Answers';

import LoginWrapper from './screens/LoginWrapper';

export default function App() {
  const [executed, setExecuted] = useState(false);

  return (
    <LoginWrapper executed={executed} setExecuted={setExecuted}>
      <Header />
      <Switch>
        <Route exact path="/">
          <Home />
        </Route>
        <Route exact path="/view/:surveyID">
          <View />
        </Route>
        <Route exact path="/my_surveys">
          <Surveys />
        </Route>
        <Route exact path="/survey/new">
          <NewSurvey />
        </Route>
        <Route exact path="/edit/:surveyID">
          <EditSurvey />
        </Route>
        <Route exact path="/questions/:surveyID">
          <Questions />
        </Route>
        <Route exact path="/answers/:surveyID">
          <Answers />
        </Route>
      </Switch>
    </LoginWrapper>
  );
}
