import React from 'react';

const Success = (message, color) => (
  <div className="w-11/12 bg-custom-400 my-2 sm:px-4 sm:py-8 px-2 py-4 rounded-lg flex flex-col items-center">
    <div
      className={`font-roboto sm:text-4xl text-xl text-${color}-300 font-bold tracking-wide flex mx-auto`}
    >
      {message}
    </div>
  </div>
);

export default Success;
