import React, { useState, useContext } from 'react';

import { SurveyContext } from '../Context';
import { useHistory } from 'react-router-dom';

import axios from 'axios';
import { v4 } from 'uuid';

import Button from '../components/Button';

export default function NewSurvey() {
  const { API_URL, setSurveys, loggedInUser } = useContext(SurveyContext);

  const [name, setName] = useState('');
  const [description, setDescription] = useState('');

  const history = useHistory();

  if (loggedInUser.uid === undefined) {
    history.push('/');
  }

  const submitNewSurvey = () => {
    const data = {
      surveyID: v4(),
      name: name,
      description: description,
      is_private: false,
      draft: false,
    };

    axios
      .post(
        `${API_URL}/survey/main/create`,
        { ...data, uid: loggedInUser.uid },
        { headers: { Authorization: `Bearer ${loggedInUser.jwt}` } }
      )
      .then((res) => {
        if (res.data.error === 0) {
          setSurveys((prev) => [
            ...prev,
            { ...data, userID: loggedInUser.uid },
          ]);
        } else {
          console.log(res.data);
        }

        history.push('/my_surveys');
      })
      .catch((e) => console.log(e));
  };

  return (
    <div className="w-5/6 mx-auto">
      <div className="font-nunito w-full text-center mx-auto font-extrabold tracking-wider text-blue-400 sm:text-3xl text-xl">
        New Survey
      </div>
      <div className="w-full flex flex-col">
        <label className="font-roboto sm:text-xl text-md tracking-wider font-bold text-gray-400 mb-2">
          Name
        </label>
        <input
          type="text"
          id="name"
          name="name"
          className="p-2 bg-gray-200 font-nunito text-gray-800 rounded-lg mb-2"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
      </div>
      <div className="w-full flex flex-col">
        <label className="font-roboto sm:text-xl text-md tracking-wider font-bold text-gray-400 mb-2">
          Description
        </label>
        <input
          type="text"
          id="description"
          name="description"
          className="p-2 bg-gray-200 font-nunito text-gray-800 rounded-lg mb-2"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
        />
      </div>
      <div className="flex justify-around w-full mt-2">
        <Button
          action={() => submitNewSurvey()}
          color="green"
          transition="color"
          width="full"
        >
          Submit
        </Button>
        <Button
          action={() => history.push('/my_surveys')}
          color="red"
          transition="color"
          width="full"
          className="ml-2"
        >
          Cancel
        </Button>
      </div>
    </div>
  );
}
