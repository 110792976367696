import React, { useContext } from 'react';

import { SurveyContext } from '../Context';
import Card from '../components/Card';
import { Link, useHistory } from 'react-router-dom';

export default function Surveys() {
  const { surveys, loggedInUser } = useContext(SurveyContext);
  const history = useHistory();

  if (loggedInUser.uid === undefined) {
    history.push('/');
  }

  let my_surveys = [];
  let my_answers = [];

  surveys.forEach((survey) => {
    if (survey.uid === loggedInUser.uid) {
      my_surveys.push(
        Card(survey.surveyID, survey.name, survey.description, 'edit')
      );
      my_answers.push(
        Card(survey.surveyID, survey.name, survey.description, 'answers')
      );
    }
  });

  return (
    <div className="w-full">
      <div className="font-nunito tracking-widest sm:text-4xl text-xl font-bold text-gray-400 sm:mx-4 mx-auto w-full text-center underline">
        My Surveys
      </div>
      <div className="w-full flex flex-col items-center">{my_surveys}</div>
      <div className="flex flex-col justify-center mx-auto w-full">
        <Link
          to="/survey/new"
          className="sm:w-2/5 w-4/5 mx-auto text-center sm:text-lg p-2 font-roboto font-bold rounded-lg bg-blue-200 hover:bg-blue-400 focus:bg-blue-400 tracking-wider sm:my-4 my-2 ease-in-out duration-300"
        >
          Create a new survey
        </Link>
        <div className="font-nunito tracking-widest sm:text-4xl text-xl font-bold text-gray-400 sm:mx-4 mx-auto w-full text-center underline">
          Answers to my Surveys
        </div>
        <div className="w-full flex flex-col items-center">{my_answers}</div>
      </div>
    </div>
  );
}
