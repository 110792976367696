import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { SurveyContext } from '../Context';
import AButton from './AButton';

export default function Login({ activateForm }) {
  const { loggedInUser, setLoggedInUser } = useContext(SurveyContext);

  if (!loggedInUser.username) {
    return (
      <div className="flex w-11/12 justify-start">
        <div className="flex flex-col text-white font-bold justify-between sm:w-1/5 w-full my-2 lg:my-4 border-2 rounded-lg border-custom-400 p-2">
          <AButton
            color="gray"
            width="full"
            href={`https://sso.konnect.dev/?origin=${
              window.location.href.split('?')[0]
            }`}
          >
            Login / Register via SSO
          </AButton>
        </div>
      </div>
    );
  } else {
    return (
      <div className="flex text-white font-bold justify-between sm:w-2/5 w-11/12 mx-auto my-2 sm:my-4">
        <div className="font-roboto font-bold p-2 tracking-wider w-full text-center sm:text-2xl text-lg border-2 rounded-lg border-custom-400">
          <span className="text-blue-300">{loggedInUser.username}</span> is
          currently logged in.
          <div className="flex justify-around">
            <Link
              to="/my_surveys"
              className="ease-in-out duration-300 font-roboto font-bold my-2 rounded-lg p-2 bg-blue-300 hover:opacity-75 focus:opacity-75 tracking-wider sm:w-1/3 w-2/5"
            >
              My Surveys
            </Link>
            <button
              onClick={() => {
                setLoggedInUser({});
                localStorage.clear();
                window.location.href = window.location.href.split('?')[0];
              }}
              className="ease-in-out duration-300 font-roboto font-bold my-2 rounded-lg p-2 bg-red-300 hover:opacity-75 focus:opacity-75 tracking-wider sm:w-1/3 w-2/5"
            >
              Log out
            </button>
          </div>
        </div>
      </div>
    );
  }
}
