import React from 'react';
import { Link } from 'react-router-dom';

const Card = (surveyID, name, description, path) => (
  <Link
    to={`/${path}/${surveyID}`}
    className="w-11/12 bg-custom-400 my-2 sm:px-4 sm:py-8 px-2 py-4 rounded-lg flex flex-col shadow-lg hover:opacity-75 focus:opacity-75 ease-in-out duration-300"
    key={`button-${surveyID}`}
  >
    <div className="font-roboto sm:text-4xl text-xl text-custom-100 font-bold tracking-wide">
      {name}
    </div>
    <div className="font-nunito sm:text-xl text-lg text-custom-100 sm:mt-2 mt-1">
      {description}
    </div>
  </Link>
);

export default Card;
