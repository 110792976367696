import React from 'react';
import ReactDOM from 'react-dom';

import { HashRouter as Router } from 'react-router-dom';

import App from './App';
import { SurveyContextProvider } from './Context';

ReactDOM.render(
  <SurveyContextProvider>
    <Router>
      <App />
    </Router>
  </SurveyContextProvider>,
  document.getElementById('root')
);
