import React from 'react';

const Title = (name, description, surveyID, is_private, draft) => (
  <div className="w-11/12 bg-custom-400 my-2 sm:px-4 sm:py-8 px-2 py-4 rounded-lg flex flex-col shadow-lg">
    <div className="w-full flex">
      <div className="font-roboto sm:text-4xl text-xl text-custom-100 font-bold tracking-wide">
        {name}
      </div>

      <button
        className="outline-none rounded-full ri-share-line hover:ri-share-fill focus:ri-share-fill sm:text-3xl text-lg bg-custom-500 text-custom-100 opacity-75 hover:opacity-100 focus:opacity-100 ease-in-out duration-300 sm:px-3 px-2 ml-2 flex justify-center items-center"
        onClick={() =>
          navigator.clipboard.writeText(
            `https://api.konnect.dev/api/v2/survey/main/fetch/info/complete?surveyID=${surveyID}`
          )
        }
      ></button>

      {is_private && (
        <div className="outline-none rounded-full sm:text-3xl text-xl bg-custom-500 text-custom-100 px-5 font-mono pt-1 ml-2 flex justify-center items-center">
          P
        </div>
      )}

      {draft && (
        <div className="outline-none rounded-full sm:text-3xl text-xl bg-custom-500 text-custom-100 px-5 font-mono pt-1 ml-2 flex justify-center items-center">
          D
        </div>
      )}
    </div>
    <div className="font-nunito sm:text-xl text-lg text-custom-100 sm:mt-2 mt-1">
      {description}
    </div>
  </div>
);

export default Title;
