import React from 'react';

const Button = ({ color, action, transition, className, width, children }) => (
  <button
    className={`${className} font-roboto font-bold rounded-lg p-2 ${
      transition === 'color'
        ? `bg-${color}-200 hover:bg-${color}-400 focus:bg-${color}-400 w-${
            width ? width : '2/5'
          }`
        : `bg-${color}-600 hover:opacity-75 focus:opacity-75 w-${
            width ? width : '2/5 sm:w-1/3'
          }`
    } sm:text-lg tracking-wider ease-in-out duration-300`}
    onClick={action}
  >
    {children}
  </button>
);

export default Button;
