import React from 'react';
import { Link, useLocation } from 'react-router-dom';

export default function Header() {
  const location = useLocation();

  return (
    <div className="bg-custom-600 py-2 w-screen flex justify-between mb-2">
      {location.pathname === '/' ? (
        <div className="font-nunito tracking-widest sm:text-2xl text-lg font-bold text-gray-400 sm:mx-4 mx-auto">
          Survey App by{' '}
          <a
            href="https://me.konnect.dev"
            className="underline hover:text-custom-300 focus:text-custom-300"
          >
            Kishan Takoordyal
          </a>
        </div>
      ) : (
        <div className="w-full flex justify-center">
          <Link
            to="/"
            className="ease-in-out duration-300 ri-home-4-line font-nunito tracking-widest sm:text-2xl text-xl font-bold text-gray-400 sm:mx-4 mx-auto p-2 rounded-full w-12 h-12 hover:bg-gray-200 hover:text-gray-800 flex justify-center items-center"
          ></Link>
        </div>
      )}
    </div>
  );
}
