import React, { useContext, useEffect, useState } from 'react';
import axios from 'axios';

import Login from '../components/Login';
import Card from '../components/Card';

import { SurveyContext } from '../Context';

export default function Home() {
  const { API_URL, loggedInUser, setLoggedInUser, surveys, setSurveys } =
    useContext(SurveyContext);

  const [auth_data, setAuthData] = useState('');
  const [password, setPassword] = useState('');

  const [showForm, setShowForm] = useState(false);

  useEffect(() => {
    axios
      .post(
        `${API_URL}/survey/main/fetch/list`,
        { uid: loggedInUser.uid },
        { headers: { Authorization: `Bearer ${loggedInUser.jwt}` } }
      )
      .then((res) => {
        if (res.data.error === 0) {
          setSurveys(res.data.surveys.reverse());
        } else {
          console.log(res.data);
        }
      });
    // eslint-disable-next-line
  }, []);

  const login = (e) => {
    e.preventDefault();
    axios
      .post(`${API_URL}/user/login`, {
        auth_data: auth_data,
        password: password,
      })
      .then((res) => {
        if (res.data.error === 0) {
          localStorage.setItem(
            '_userData',
            JSON.stringify({
              uid: res.data.uid,
              jwt: res.data.jwt,
            })
          );

          axios
            .post(
              `${API_URL}/survey/main/fetch/list`,
              { uid: res.data.uid },
              { headers: { Authorization: `Bearer ${res.data.jwt}` } }
            )
            .then((response) => {
              if (response.data.error === 0) {
                setSurveys(response.data.surveys.reverse());
              } else {
                console.log(response.data);
              }

              setLoggedInUser(res.data);
              reset();
            });
        } else {
          console.log(res.data);
          alert('Login Failed!');
          reset();
        }
      });
  };

  const reset = () => {
    setShowForm(false);
    setAuthData('');
    setPassword('');
  };

  const activateForm = (e, type) => {
    e.preventDefault();
    setShowForm(true);
  };

  const cards = !surveys
    ? 'Loading...'
    : surveys.map((survey) =>
        Card(survey.surveyID, survey.name, survey.description, 'view')
      );

  return (
    <div className="w-full">
      {!showForm ? (
        <div className="flex flex-col w-full items-center">
          <Login activateForm={activateForm} />
          {cards}
        </div>
      ) : (
        <div className="flex flex-col sm:w-2/5 w-11/12 mx-auto p-4 border-2 border-custom-400 rounded-lg">
          <div className="font-nunito w-full text-center mx-auto font-extrabold tracking-wider text-blue-400 sm:text-3xl text-xl">
            Login
          </div>
          <div className="w-full flex flex-col">
            <label className="font-roboto sm:text-xl text-md tracking-wider font-bold text-gray-400 mb-2">
              Enter Username or Email Address
            </label>
            <input
              type="text"
              id="email"
              name="email"
              className="p-2 bg-gray-200 font-nunito text-gray-800 rounded-lg mb-2"
              value={auth_data}
              onChange={(e) => setAuthData(e.target.value)}
            />
          </div>
          <div className="w-full flex flex-col mt-2">
            <label className="font-roboto sm:text-xl text-md tracking-wider font-bold text-gray-400 mb-2">
              Enter Password
            </label>
            <input
              type="password"
              id="password"
              name="password"
              className="p-2 bg-gray-200 font-nunito text-gray-800 rounded-lg mb-2"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          <div className="flex sm:flex-row flex-col w-full sm:justify-end mt-2 sm:mt-0">
            <button
              className={`w-full sm:text-lg mx-auto p-2 font-roboto font-bold rounded-lg bg-blue-200 ${
                auth_data && password
                  ? 'hover:bg-blue-400 focus:bg-blue-400'
                  : 'opacity-50'
              } tracking-wider sm:mt-4 sm:mb-2 mt-2 ease-in-out duration-300`}
              onClick={(e) => (auth_data && password ? login(e) : null)}
            >
              Submit
            </button>
            <button
              className="w-full sm:ml-2 sm:text-lg mx-auto p-2 font-roboto font-bold rounded-lg bg-red-200 hover:bg-red-400 focus:bg-red-400 tracking-wider sm:mt-4 sm:mb-2 mt-2 ease-in-out duration-300"
              onClick={() => setShowForm(false)}
            >
              Cancel
            </button>
          </div>
        </div>
      )}
    </div>
  );
}
