import React, { useState } from 'react';

const SurveyContext = React.createContext();

const SurveyContextProvider = ({ children }) => {
  // const [API_URL] = useState("http://localhost:32000/api/v2");
  const [API_URL] = useState('https://api.konnect.dev/api/v2');

  const [loggedInUser, setLoggedInUser] = useState({});
  const [surveys, setSurveys] = useState([]);

  return (
    <SurveyContext.Provider
      value={{
        API_URL,
        loggedInUser,
        setLoggedInUser,
        surveys,
        setSurveys,
      }}
    >
      {children}
    </SurveyContext.Provider>
  );
};

export { SurveyContext, SurveyContextProvider };
