import React, { useState, useContext, useEffect } from 'react';

import { SurveyContext } from '../Context';
import { useHistory, useParams } from 'react-router-dom';

import axios from 'axios';

import Button from '../components/Button';

export default function EditSurvey() {
  const { API_URL, surveys, setSurveys, loggedInUser } =
    useContext(SurveyContext);

  const { surveyID } = useParams();

  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [isPrivate, setIsPrivate] = useState(false);
  const [draft, setDraft] = useState(false);

  const history = useHistory();

  useEffect(() => {
    if (loggedInUser.uid === undefined) {
      history.push('/');
    }

    axios
      .post(
        `${API_URL}/survey/main/fetch/one`,
        {
          surveyID: surveyID,
          uid: loggedInUser.uid ? loggedInUser.uid : '',
        },
        {
          headers: {
            Authorization: `Bearer ${loggedInUser.jwt ? loggedInUser.jwt : ''}`,
          },
        }
      )
      .then((res) => {
        if (res.data.error === 0) {
          setIsPrivate(res.data.survey.private);
          setDraft(res.data.survey.draft);
        }
      });

    const currentSurvey = surveys.find(
      (survey) => survey.surveyID === surveyID
    );

    if (!currentSurvey || !currentSurvey.name) {
      history.push('/my_surveys');
      return;
    }

    setName(currentSurvey.name);
    setDescription(currentSurvey.description);
    // eslint-disable-next-line
  }, []);

  const submitUpdatedSurvey = () => {
    const data = {
      uid: loggedInUser.uid,
      surveyID: surveyID,
      name: name,
      description: description,
    };

    axios
      .post(
        `${API_URL}/survey/main/update`,
        { ...data },
        { headers: { Authorization: `Bearer ${loggedInUser.jwt}` } }
      )
      .then((res) => {
        if (res.data.error === 0) {
          const oldSurveys = surveys.filter(
            (survey) => survey.surveyID !== surveyID
          );
          setSurveys([...oldSurveys, { ...data }]);
        } else {
          console.log(res.data);
        }

        setTimeout(() => {
          history.push('/my_surveys');
        }, 500);
      });
  };

  const markPrivate = (state) => {
    const data = {
      uid: loggedInUser.uid,
      surveyID: surveyID,
      is_private: state,
    };

    axios
      .post(
        `${API_URL}/survey/main/private`,
        { ...data },
        { headers: { Authorization: `Bearer ${loggedInUser.jwt}` } }
      )
      .then((res) => {
        if (res.data.error === 0) {
          setIsPrivate(state);
        } else {
          console.log(res.data);
        }
      });
  };

  const markDraft = (state) => {
    const data = {
      uid: loggedInUser.uid,
      surveyID: surveyID,
      draft: state,
    };

    axios
      .post(
        `${API_URL}/survey/main/draft`,
        { ...data },
        { headers: { Authorization: `Bearer ${loggedInUser.jwt}` } }
      )
      .then((res) => {
        if (res.data.error === 0) {
          setDraft(state);
        } else {
          console.log(res.data);
        }
      });
  };

  const deleteSurvey = () => {
    if (window.confirm('Are you sure you want to delete this survey?')) {
      const data = {
        uid: loggedInUser.uid,
        surveyID: surveyID,
      };

      axios
        .post(
          `${API_URL}/survey/main/delete`,
          { ...data },
          { headers: { Authorization: `Bearer ${loggedInUser.jwt}` } }
        )
        .then((res) => {
          if (res.data.error === 0) {
            setSurveys((prev) =>
              prev.filter((survey) => survey.surveyID !== surveyID)
            );
          } else {
            console.log(res.data);
          }

          setTimeout(() => {
            history.push('/my_surveys');
          }, 500);
        });
    } else {
      history.push('/my_surveys');
    }
  };

  return (
    <div className="w-11/12 mx-auto">
      <div className="font-nunito w-full text-center mx-auto font-extrabold tracking-wider text-blue-400 sm:text-3xl text-xl">
        Edit Survey
      </div>
      <div className="w-full flex flex-col">
        <label className="font-roboto sm:text-xl text-md tracking-wider font-bold text-gray-400 mb-2">
          Name
        </label>
        <input
          type="text"
          id="name"
          name="name"
          className="p-2 bg-gray-200 font-nunito text-gray-800 rounded-lg mb-2"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
      </div>
      <div className="w-full flex flex-col">
        <label className="font-roboto sm:text-xl text-md tracking-wider font-bold text-gray-400 mb-2">
          Description
        </label>
        <input
          type="text"
          id="description"
          name="description"
          className="p-2 bg-gray-200 font-nunito text-gray-800 rounded-lg mb-2"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
        />
      </div>
      <div className="flex sm:flex-row flex-col justify-around sm:items-start items-center mx-auto mt-2">
        <Button
          action={() => submitUpdatedSurvey()}
          color="green"
          transition="color"
          width="full"
        >
          Update
        </Button>
        <div className="opacity-0 h-0 mt-4">.</div>
        <Button
          action={() => history.push(`/questions/${surveyID}`)}
          color="blue"
          transition="color"
          width="full"
          className="sm:ml-2"
        >
          Edit Questions
        </Button>
      </div>
      <div className="flex sm:flex-row flex-col justify-around sm:items-start items-center mx-auto mt-4">
        <Button
          action={() => markPrivate(!isPrivate)}
          color="pink"
          transition="color"
          width="full"
        >
          {isPrivate ? 'Unmark' : 'Mark'} as Private
        </Button>
        <div className="opacity-0 h-0 mt-4">.</div>
        <Button
          action={() => markDraft(!draft)}
          color="purple"
          transition="color"
          width="full"
          className="sm:ml-2"
        >
          {draft ? 'Unmark' : 'Mark'} as Draft
        </Button>
      </div>
      <div className="flex sm:flex-row flex-col justify-around sm:items-start items-center mx-auto mt-4">
        <Button
          action={() => deleteSurvey()}
          color="orange"
          transition="color"
          width="full"
        >
          Delete Survey
        </Button>
        <div className="opacity-0 h-0 mt-4">.</div>
        <Button
          action={() => history.push('/my_surveys')}
          color="red"
          transition="color"
          width="full"
          className="sm:ml-2"
        >
          Cancel
        </Button>
      </div>
    </div>
  );
}
