import React from 'react';

const AButton = ({
  color,
  action,
  transition,
  className,
  width,
  children,
  href,
}) => (
  <a
    className={`${className} font-roboto font-bold rounded-lg text-center p-2 ${
      transition === 'color'
        ? `bg-${color}-200 hover:bg-${color}-400 focus:bg-${color}-400 w-${
            width ? width : '2/5'
          }`
        : `bg-${color}-600 hover:opacity-75 focus:opacity-75 w-${
            width ? width : '2/5 sm:w-1/3'
          }`
    } sm:text-lg tracking-wider ease-in-out duration-300`}
    href={href}
  >
    {children}
  </a>
);

export default AButton;
