import React, { useContext, useEffect } from 'react';
import axios from 'axios';

import { SurveyContext } from '../Context';

export default function LoginWrapper({ children, executed, setExecuted }) {
  const { API_URL, loggedInUser, setLoggedInUser, setSurveys } =
    useContext(SurveyContext);

  useEffect(() => {
    let url = window.location.href;
    if (url.includes('uid') && url.includes('jwt')) {
      let uid = window.location.href.split('uid=')[1];
      if (uid.includes('&')) {
        uid = uid.split('&')[0];
      }

      let jwt = window.location.href.split('jwt=')[1];
      if (jwt.includes('&')) {
        jwt = jwt.split('&')[0];
      }

      let t = window.location.href.split('theme=')[1];
      if (t.includes('&')) {
        t = t.split('&')[0];
      }

      let lang = window.location.href.split('lang=')[1];
      if (lang.includes('&')) {
        lang = lang.split('&')[0];
      }
      if (lang.includes('#/')) {
        lang = lang.split('#/')[0];
      }

      localStorage.setItem(
        '_userData',
        JSON.stringify({
          uid: uid,
          jwt: jwt,
          theme: t ? t : 'dark',
          lang: lang ? lang : 'en',
        })
      );

      window.location.href = url.split('?')[0];
    }
  });

  useEffect(() => {
    async function fetchLocal() {
      if (executed) {
        return;
      }

      if (localStorage.getItem('_userData')) {
        const { uid, jwt } = JSON.parse(localStorage.getItem('_userData'));

        if (loggedInUser.jwt !== undefined && loggedInUser.jwt) {
          setExecuted(true);
          return;
        }

        await axios
          .post(
            `${API_URL}/user/login/jwt`,
            {
              uid,
            },
            { headers: { Authorization: `Bearer ${jwt}` } }
          )
          .then((res) => {
            if (res.data.error === 0) {
              axios
                .post(
                  `${API_URL}/survey/main/fetch/list`,
                  { uid: res.data.uid },
                  { headers: { Authorization: `Bearer ${res.data.jwt}` } }
                )
                .then((response) => {
                  if (response.data.error === 0) {
                    setSurveys(response.data.surveys.reverse());
                  } else {
                    console.log(response.data);
                  }

                  setLoggedInUser(res.data);
                });
            } else {
              console.log(res.data);
            }

            setExecuted(true);
          });
      }
    }

    fetchLocal();
    setTimeout(() => setExecuted(true), 1000);

    // eslint-disable-next-line
  }, []);

  return <div className="flex flex-col w-screen h-screen">{children}</div>;
}
