import React from 'react';

export default function Question({
  questionID,
  text,
  type,
  required,
  data,
  answers,
  setAnswers,
}) {
  const handleChange = (e, value) => {
    e.persist();
    setAnswers((prev) => {
      //   if (answer.length === 0) {
      //     return [{ questionID, type, data: e.target.value }];
      //   }
      const update = prev.filter((prev) => prev.questionID !== questionID);

      if (type !== 'multiple') {
        return [...update, { questionID, type, data: [e.target.value] }];
      } else {
        let { data } = prev.find((p) => p.questionID === questionID);

        if (data.includes(value)) {
          data = data.filter((d) => d !== value);
        } else {
          data = [...data, value];
        }

        return [...update, { questionID, type, data: data }];
      }
    });
  };

  let answerContainer = (
    <textarea
      name="answer"
      id="answer"
      value={answers.find((a) => a.questionID === questionID).data}
      onChange={(e) => handleChange(e)}
      className="w-full bg-gray-300 text-gray-800 placeholder-gray-600 rounded mt-2 sm:-mb-4 p-2 font-roboto tracking-wide sm:text-md text-sm"
      placeholder="Enter your answer..."
      required={required}
    />
  );

  if (type === 'choice') {
    answerContainer = data.map((d, i) => (
      <label
        className="mt-2 -mb-4 p-2 font-roboto tracking-wider font-bold sm:w-1/6 text-gray-800"
        key={`${questionID}-choice-${i}`}
      >
        <input
          type="radio"
          name="answer"
          id="answer"
          value={d}
          onChange={(e) => handleChange(e)}
          required={required}
        />{' '}
        {d}
      </label>
    ));
  } else if (type === 'multiple') {
    answerContainer = data.map((d, i) => (
      <label
        className="mt-2 -mb-4 p-2 font-roboto tracking-wider font-bold sm:w-1/6 text-gray-800"
        key={`${questionID}-mult-${i}`}
      >
        <input
          type="checkbox"
          name="answer"
          id="answer"
          onChange={(e) => handleChange(e, d)}
          required={required}
        />{' '}
        {d}
      </label>
    ));
  }

  return (
    <div
      className="w-11/12 bg-custom-400 my-2 sm:px-4 sm:py-8 px-2 py-4 rounded-lg flex flex-col shadow-lg"
      key={`q-${questionID}`}
    >
      <div className="font-roboto sm:text-4xl text-xl text-gray-800 font-bold tracking-wide flex">
        {text}
        <p className="ml-1 text-yellow-200">{required ? '*' : ''}</p>
      </div>
      <div className="flex flex-col">{answerContainer}</div>
    </div>
  );
}
