import React, { useState, useContext, useEffect } from 'react';

import { SurveyContext } from '../Context';
import { useHistory, useParams } from 'react-router-dom';
import { v4 } from 'uuid';

import axios from 'axios';

import Title from '../components/Title';
import EditQuestion from '../components/EditQuestion';
import Button from '../components/Button';

export default function EditSurvey() {
  const { API_URL, surveys, loggedInUser } = useContext(SurveyContext);

  const { surveyID } = useParams();

  const [currentSurvey, setCurrentSurvey] = useState({});
  const [questions, setQuestions] = useState([]);

  const history = useHistory();

  useEffect(() => {
    if (!loggedInUser.uid || loggedInUser.uid === undefined) {
      history.push('/');
    }

    setCurrentSurvey(surveys.find((survey) => survey.surveyID === surveyID));

    if (!currentSurvey) history.push('/my_surveys');

    axios
      .post(
        `${API_URL}/survey/main/fetch/one`,
        {
          uid: loggedInUser.uid,
          surveyID: surveyID,
        },
        { headers: { Authorization: `Bearer ${loggedInUser.jwt}` } }
      )
      .then((res) => {
        if (res.data.error === 0) {
          setQuestions(res.data.survey.questions);
        } else {
          console.log(res.data);
        }
      });
    // eslint-disable-next-line
  }, []);

  const addQuestion = () => {
    const uid = v4();

    setQuestions((prev) => [
      ...prev,
      {
        questionID: uid,
        text: '',
        type: 'text',
        required: false,
        data: [],
      },
    ]);
  };

  const saveSurvey = () => {
    axios
      .post(
        `${API_URL}/survey/question/add/multiple`,
        {
          uid: loggedInUser.uid,
          surveyID: surveyID,
          questions: [...questions],
        },
        { headers: { Authorization: `Bearer ${loggedInUser.jwt}` } }
      )
      .then((res) => {
        if (res.data.error !== 0) {
          console.log(res.data);
        }
      });

    setTimeout(() => {
      history.push('/my_surveys');
    }, 500);
  };

  const surveyCard = Title(
    currentSurvey.name,
    currentSurvey.description,
    currentSurvey.surveyID,
    currentSurvey.private,
    currentSurvey.draft
  );

  const surveyQuestions =
    questions.length > 0
      ? questions.map((question) => {
          return EditQuestion({
            ...question,
            questions,
            setQuestions,
            history,
          });
        })
      : null;

  return (
    <div className="w-full flex flex-col items-center">
      {surveyCard}
      {surveyQuestions}
      <div className="flex sm:flex-row flex-col justify-around sm:items-start items-center mx-auto my-4 w-11/12">
        <Button
          action={() => addQuestion()}
          color="blue"
          transition="color"
          width="full"
        >
          Add new question
        </Button>
        <Button
          action={() => saveSurvey()}
          color="green"
          transition="color"
          width="full"
          className="lg:mx-2 lg:my-0 my-2"
        >
          Save
        </Button>
        <Button
          action={() => history.push('/my_surveys')}
          color="red"
          transition="color"
          width="full"
        >
          Cancel
        </Button>
      </div>
    </div>
  );
}
